import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import "../App.css"
import LoadingSpinner from "../Components/LoadingSpinner";
import { AuthContext } from '../contexts/AuthProvider';
function Main() {

  const {user,loading} =useContext(AuthContext);
 
  return (

    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {" "}
          <Navbar />
          <div className="min-h-screen">
            <Outlet />
          </div>
          <Footer />
        </div>
      )}
    </div>
    
  )
}

export default Main;