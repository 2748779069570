import React , {createContext , useState,useEffect} from 'react'

import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateProfile,
} from "firebase/auth";
import app from "../firebase/firebase.config";


export const AuthContext=createContext();
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const AuthProvider = ({children}) => {

    const [user , setUser]=useState(null);
    const [loading , setLoading]=useState(true);


    // creating  an account
const createUser = (email, password) => {
    setLoading(true);
    return createUserWithEmailAndPassword(auth, email, password);
};

 // signing up  with gmail
const signUpWithGmail = () => {
    setLoading(true);
    return signInWithPopup(auth, googleProvider);
};

  // login using email & password
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };



  // logging out 
  const logout = () => {
    return signOut(auth);
  };

  // updating user profile
  const updateUserProfile = (name, photoURL) => {
    return updateProfile(auth.currentUser, {
      displayName: name,
      photoURL: photoURL,
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    
      setUser(currentUser);
      setLoading(false);
    });

    return () => {
      return unsubscribe();
    };
}, []);


    const authInfo={

        user,
        createUser,
        signUpWithGmail,
        login,
        logout,
        updateUserProfile,
        loading,
    };
  return (
    <AuthContext.Provider value = {authInfo}>

    {children}


    </AuthContext.Provider>
  );
};

export default AuthProvider
