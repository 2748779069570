import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';

import {RouterProvider} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Router from "./router/Router.js";
import AuthProvider from "./contexts/AuthProvider.js";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <AuthProvider>
    <RouterProvider router={Router}/>
  </AuthProvider>
  
);

